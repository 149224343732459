<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        disable-query-params
        :fixed-items-per-page="10"
        :fixed-filters="fixedFilter"
    >
        <template slot="before">
            <form-two-col-row class="align-end mb-4">
                <template v-slot:col1>
                    <entity-select-input
                        v-model="admin"
                        v-validate="''"
                        label="labels.users"
                        name="users"
                        :entity="selectApiClientType.USER"
                        :query-params="{ can_be_admin: true }"
                        autocomplete
                        :data-vv-as="$t('labels.users')"
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <v-btn
                        color="primary"
                        class="white--text"
                        :disabled="!admin"
                        @click="toggleAdmin(admin)"
                    >
                        <v-icon left>add</v-icon>
                        {{ $t('labels.add_new') }}
                    </v-btn>
                </template>
            </form-two-col-row>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                row-action
                class="inlined table"
                @onRowSelected="onRowSelected"
            >
                <!-- name column -->
                <template v-slot:[`item.lastName`]="{ item }">
                    <div>
                        <a
                            href="#"
                            class="table-edit-link"
                            @click.prevent="
                                $router.push({
                                    name: routeType.USER_EDIT,
                                    params: { userId: item.id },
                                    query: {
                                        redirectTo: $route.fullPath,
                                    },
                                })
                            "
                        >
                            <span
                                :class="{
                                    'active-marker': item.enabled,
                                    'inactive-marker': !item.enabled,
                                }"
                            ></span>
                            {{ item.lastName }} {{ item.firstName }}<br />
                        </a>
                        <span class="small">#{{ item.id }}</span>
                    </div>
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <edit-button
                        :to="{
                            name: routeType.USER_EDIT,
                            params: { userId: item.id },
                            query: {
                                redirectTo: $route.fullPath,
                            },
                        }"
                    ></edit-button>
                    <table-button
                        :tooltip="$t('actions.delete')"
                        color="blue-grey"
                        icon="delete"
                        approve-color="error"
                        @execute="toggleAdmin(item['@id'], true)"
                    ></table-button>
                </template>
            </d-table>
        </template>

        <template slot="after">
            <create-button
                class="inlined create-button mt-5"
                depressed
                :label="$t('labels.add_new')"
                @create="
                    $router.push({
                        name: routeType.USER_CREATE,
                        query: queryParam,
                    })
                "
            ></create-button>
        </template>
    </data-iterator>
</template>

<script>
import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import TableButton from '@/components/table/buttons/TableButton'
import CreateButton from '@/components/form/Actions/CreateButton'
import InlineAdminsHeaders from '@/pages/restaurants/mixins/InlineAdminsHeaders'

export default {
    components: {
        CreateButton,
        EntitySelectInput,
        FormTwoColRow,
        TableButton,
    },

    mixins: [DataIteratorMixin, DataTableMixin, InlineAdminsHeaders],
    props: {
        entity: {
            type: String,
            required: true,
        },
        propKey: {
            type: String,
            required: true,
        },
        queryParamKey: {
            type: String,
            required: true,
        },
        linkQueryKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.USERS,
            selectApiClientType: SelectApiClientType,
            mapperType: MapperType,
            admin: null,
        }
    },
    computed: {
        queryParam() {
            let obj = { redirectTo: this.$route.fullPath }
            obj[this.linkQueryKey] = this.entity
            return obj
        },

        fixedFilter() {
            const filters = {}
            filters[`userRoles.${this.queryParamKey}`] = {}
            filters[`userRoles.${this.queryParamKey}`]['exact'] = this.entity
            return filters
        },
    },

    methods: {
        toggleAdmin(user, remove = false) {
            const parts = user.split('/')
            const entityId = parts[parts.length - 1]

            let obj = {}
            obj[this.propKey] = remove ? null : this.entity

            let response

            const entity = MapperCollection.get(
                this.mapperType.USERS_FORM
            ).toEntity(obj)

            response = RestApiCollection.get(this.api).update(entity, entityId)

            response.then(() => {
                this.$refs.iterator.updateItems()
            })
        },
    },
}
</script>
