<script>
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import PolygonInput from '@/components/form/Inputs/google/PolygonInput'
import CircleInput from '@/components/form/Inputs/google/CircleInput'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

export default {
    components: {
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        PolygonInput,
        CircleInput,
    },

    mixins: [FormInput, FormTabMixin],

    props: {
        modelObject: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    computed: {
        showRadius() {
            return this.modelObject.deliveryAreaType == 'circle'
        },
    },

    watch: {
        'model.deliveryMinTime'(val) {
            if (val == '') this.$set(this.model, 'deliveryMinTime', 0)
        },
        'model.deliveryMaxTime'(val) {
            if (val == '') this.$set(this.model, 'deliveryMaxTime', 0)
        },
        'model.deliveryDelay'(val) {
            if (val == '') this.$set(this.model, 'deliveryDelay', 0)
        },
        'model.minimumOrder'(val) {
            if (val == '') this.$set(this.model, 'minimumOrder', '0')
        },
        'model.deliveryPrice'(val) {
            if (val == '') this.$set(this.model, 'deliveryPrice', '0')
        },
        'model.deliveryPriceLimit'(val) {
            if (val == '') this.$set(this.model, 'deliveryPriceLimit', '0')
        },
    },
}
</script>
<template>
    <div v-if="model" class="delivery-area">
        <form-two-col-row>
            <template v-slot:col1>
                <text-input
                    v-model="model.name"
                    v-validate.immediate="'required|min:2|max:255|space'"
                    v-tab-error:deliveryZones
                    name="name"
                    label="labels.name"
                    :error-messages="errors.collect('name')"
                    :data-vv-as="$t('labels.name')"
                ></text-input>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.minimumOrder"
                            v-tab-error:deliveryZones
                            v-validate="'space'"
                            name="minimumOrder"
                            label="labels.minimum_order"
                            :error-messages="errors.collect('minimumOrder')"
                            :data-vv-as="$t('labels.minimum_order')"
                        ></text-input>
                    </template>
                    <template v-slot:col2>
                        <text-input
                            v-model="model.deliveryPrice"
                            v-validate="'space'"
                            v-tab-error:deliveryZones
                            name="deliveryPrice"
                            label="labels.delivery_price"
                            :error-messages="errors.collect('delivery_price')"
                            :data-vv-as="$t('labels.delivery_price')"
                        ></text-input>
                    </template>
                </form-two-col-row>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.deliveryPriceLimit"
                            v-tab-error:deliveryZones
                            name="deliveryPriceLimit"
                            label="labels.delivery_price_limit"
                            :error-messages="
                                errors.collect('deliveryPriceLimit')
                            "
                            :data-vv-as="$t('labels.delivery_price_limit')"
                        ></text-input>
                    </template>
                </form-two-col-row>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.deliveryMinTime"
                            v-tab-error:deliveryZones
                            v-validate.immediate="'required|integer'"
                            type="number"
                            name="deliveryMinTime"
                            label="labels.delivery_min_time"
                            :error-messages="errors.collect('deliveryMinTime')"
                            :data-vv-as="$t('labels.delivery_min_time')"
                        ></text-input>
                    </template>
                    <template v-slot:col2>
                        <text-input
                            v-model="model.deliveryMaxTime"
                            v-tab-error:deliveryZones
                            v-validate.immediate="'required|integer'"
                            type="number"
                            name="deliveryMaxTime"
                            label="labels.delivery_max_time"
                            :error-messages="errors.collect('deliveryMaxTime')"
                            :data-vv-as="$t('labels.delivery_max_time')"
                        ></text-input>
                    </template>
                </form-two-col-row>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.deliveryDelay"
                            v-tab-error:deliveryZones
                            v-validate.immediate="'required|integer'"
                            type="number"
                            name="deliveryDelay"
                            label="labels.delivery_delay"
                            :error-messages="errors.collect('deliveryDelay')"
                            :data-vv-as="$t('labels.delivery_delay')"
                        ></text-input>
                    </template>
                    <template v-slot:col2>
                        <checkbox-input
                            v-model="model.isActive"
                            v-tab-error:deliveryZones
                            v-validate="''"
                            label="labels.active"
                            name="isActive"
                            :error-messages="errors.collect('isActive')"
                        ></checkbox-input>
                    </template>
                </form-two-col-row>
            </template>
            <template v-slot:col2>
                <polygon-input
                    v-if="!showRadius"
                    v-model="model"
                    style="height: 300px"
                    :model-object="modelObject"
                    @tabError="tabErrorsContainer = $event"
                ></polygon-input>
                <circle-input
                    v-else
                    v-model="model"
                    style="height: 300px"
                    :model-object="modelObject"
                ></circle-input>
                <text-input
                    v-if="showRadius"
                    v-model.number="model.deliveryRadius"
                    v-validate="'required|integer|space'"
                    v-tab-error:deliveryZones
                    type="number"
                    name="deliveryRadius"
                    label="labels.delivery_radius"
                    :error-messages="errors.collect('deliveryRadius')"
                    :data-vv-as="$t('labels.delivery_radius')"
                ></text-input>
            </template>
        </form-two-col-row>

        <div class="d-flex justify-end">
            <v-btn
                class="ma-2"
                outlined
                color="error"
                @click="$emit('remove-area', model)"
            >
                <v-icon left>remove</v-icon> {{ $t('actions.remove') }}
            </v-btn>
        </div>
    </div>
</template>
