<script>
import SelectApiClientType from '@/api/SelectApiClientType'
import configType from '@/store/type/configType'

import { mapGetters } from 'vuex'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormOneColRow from '@/components/form/FormOneColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import TransTextInput from '@/components/form/Inputs/TransTextInput'
import TransTextareaInput from '@/components/form/Inputs/TransTextareaInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import InlineAdminsTable from '@/components/pages/users/InlineAdminsTable'
import SelectInput from '@/components/form/Inputs/SelectInput'

import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormInput from '@/components/mixins/FormInput'
import ImageEditor from '@/components/pages/meals/ImageEditor'

export default {
    components: {
        ImageEditor,
        FormPanel,
        FormTwoColRow,
        TextInput,
        TransTextInput,
        TransTextareaInput,
        EntitySelectInput,
        CheckboxInput,
        InlineAdminsTable,
        SelectInput,
        FormOneColRow,
    },

    inject: {
        $validator: 'formValidator',
    },

    mixins: [PhoneNumberMixin, FormTabMixin, FormInput, AuthMixin],

    data() {
        return {
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
    },

    watch: {
        'model.isEnabledForRestaurantAdmin': function (value) {
            if (!value) {
                this.model.isActive = false
            }
        },
    },
}
</script>

<template>
    <v-tab-item
        key="general_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <!-- PROFILE INFORMATION -->
        <form-panel :title="$t('labels.profile_information')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- NAME -->
                    <text-input
                        v-model="model.name"
                        v-validate.immediate="'required|min:2|max:255|space'"
                        v-tab-error:general
                        name="name"
                        label="labels.name"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- PHONE -->
                    <text-input
                        v-model="model.phone"
                        v-validate.immediate="'required|phonenumber'"
                        v-tab-error:general
                        name="phone"
                        label="labels.phone"
                        :error-messages="errors.collect('phone')"
                        :data-vv-as="$t('labels.phone')"
                        @blur="model.phone = formatPhoneNumber(model.phone)"
                    ></text-input>
                </template>
            </form-two-col-row>

            <form-two-col-row v-if="model.restaurantDiscount">
                <template v-slot:col1>
                    <!-- DiscountPriceEnabled -->
                    <checkbox-input
                        v-model="model.restaurantDiscount.discountPriceEnabled"
                        v-tab-error:general
                        class="mt-0"
                        label="labels.discountPriceEnabled"
                        name="discountPriceEnabled"
                        :error-messages="errors.collect('discountPriceEnabled')"
                    ></checkbox-input>
                </template>
                <template v-slot:col2>
                    <!-- DiscountPriceOnlyForCanteen -->
                    <checkbox-input
                        v-model="
                            model.restaurantDiscount.discountPriceOnlyForCanteen
                        "
                        v-tab-error:general
                        class="mt-0"
                        label="labels.discountPriceOnlyForCanteen"
                        name="discountPriceOnlyForCanteen"
                        :error-messages="
                            errors.collect('discountPriceOnlyForCanteen')
                        "
                    ></checkbox-input>
                </template>
            </form-two-col-row>

            <form-two-col-row v-if="model.translations">
                <template v-slot:col1>
                    <!-- MENU BANNER NOTIFICATION -->
                    <trans-textarea-input
                        v-model="model.translations"
                        v-tab-error:general
                        validators="space"
                        :error-messages="errors.collect('banner')"
                        field="banner"
                        name="banner"
                        label="labels.menu_banner_notification"
                    ></trans-textarea-input>
                </template>
                <template v-slot:col2>
                    <!-- DISPLAY IMAGE LABEL -->
                    <trans-text-input
                        v-model="model.translations"
                        v-tab-error:general
                        validators="space"
                        :error-messages="errors.collect('badge')"
                        field="badge"
                        name="badge"
                        label="labels.display_image_label"
                    ></trans-text-input>
                </template>
            </form-two-col-row>

            <form-one-col-row v-if="model.translations">
                <!-- DESCRIPTION -->
                <trans-textarea-input
                    v-model="model.translations"
                    v-tab-error:general
                    validators="space"
                    field="description"
                    name="description"
                    :error-messages="errors.collect('description')"
                    label="labels.description"
                ></trans-textarea-input>
            </form-one-col-row>

            <form-one-col-row>
                <!-- TAGS -->
                <entity-select-input
                    v-model="model.tags"
                    v-tab-error:general
                    v-validate.immediate="''"
                    label="labels.tags"
                    name="tags"
                    autocomplete
                    :entity="selectApiClientType.RESTAURANT_TAGS"
                    :data-vv-as="$t('labels.tags')"
                    :error-messages="errors.collect('tags')"
                    :query-params="{ locale: currentLocale }"
                    multi-select
                ></entity-select-input>
            </form-one-col-row>

            <form-two-col-row>
                <template v-slot:col1>
                    <!-- ACTIVE -->
                    <checkbox-input
                        v-model="model.isActive"
                        v-tab-error:general
                        label="labels.active"
                        :disabled="!model.isEnabledForRestaurantAdmin"
                        name="isActive"
                        :error-messages="errors.collect('isActive')"
                    ></checkbox-input>
                </template>
                <template v-slot:col2>
                    <!-- EXPORT ENABLED -->
                    <checkbox-input
                        v-model="model.exportEnabled"
                        v-tab-error:general
                        label="labels.can_export_orders"
                        name="exportEnabled"
                        :error-messages="errors.collect('exportEnabled')"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
            <form-two-col-row v-if="isSuperAdmin">
                <template v-slot:col1>
                    <!-- ISENABLEDFORRESTAURANTADMIN -->
                    <checkbox-input
                        v-model="model.isEnabledForRestaurantAdmin"
                        v-tab-error:general
                        label="labels.is_enabled_for_restaurant_admin"
                        name="isEnabledForRestaurantAdmin"
                        :error-messages="
                            errors.collect('isEnabledForRestaurantAdmin')
                        "
                    ></checkbox-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <form-panel :title="$t('labels.images')">
            <image-editor
                v-model="model"
                :primary-aspect-ratio="1.978"
                :secondary-aspect-ratio="4"
                :primary-size="{ height: 186, width: 386 }"
                :secondary-size="{ height: 320, width: 1280 }"
                primary-key="cardImageMedia"
                secondary-key="coverImageMedia"
                primary-label="labels.display_image"
                secondary-label="labels.cover_image"
            ></image-editor>
        </form-panel>

        <form-panel v-if="model['@id']" :title="$t('labels.admins')">
            <inline-admins-table
                :entity="model['@id']"
                prop-key="adminOfRestaurant"
                query-param-key="restaurant"
                link-query-key="restaurant"
            ></inline-admins-table>
        </form-panel>
        <form-panel :title="$t('labels.other')">
            <form-two-col-row>
                <!-- COUNTRY -->
                <template v-slot:col1>
                    <entity-select-input
                        v-model="model.country"
                        v-validate.immediate="'required'"
                        v-tab-error:general
                        label="labels.country"
                        :data-vv-as="$t('labels.country')"
                        :entity="selectApiClientType.COUNTRY"
                        name="country"
                        :error-messages="errors.collect('country')"
                    ></entity-select-input>
                </template>
                <!-- TIMEZONE -->
                <template v-slot:col2>
                    <select-input
                        v-model="model.timezoneRegion"
                        v-validate.immediate="'required'"
                        v-tab-error:general
                        label="labels.timezone"
                        name="timezoneRegion"
                        :data-vv-as="$t('labels.timezone')"
                        :error-messages="errors.collect('timezoneRegion')"
                        :items="[
                            {
                                title: 'Europe/Budapest',
                                value: 'Europe/Budapest',
                            },
                            {
                                title: 'Europe/London',
                                value: 'Europe/London',
                            },
                        ]"
                    ></select-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="model.deliverooUrl"
                        v-validate.immediate="'max:255|space'"
                        v-tab-error:general
                        name="deliverooUrl"
                        label="labels.deliveroo_url"
                        :error-messages="errors.collect('deliverooUrl')"
                        :data-vv-as="$t('labels.deliveroo_url')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </v-tab-item>
</template>
