<script>
import MealCategoryMenu from '@/components/pages/meals/mealCategory/MealCategoryMenu'
import AddMealCategory from '@/components/pages/meals/mealCategory/AddMealCategory'
import MealCategory from '@/components/pages/meals/mealCategory/MealCategory'
import Meal from '@/components/pages/meals/Meal'

import { mapGetters } from 'vuex'
import mealType from '@/store/type/mealType'

import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

export default {
    components: {
        MealCategoryMenu,
        AddMealCategory,
        MealCategory,
        Meal,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            api: RestApiType.MEAL,
            apiCategory: RestApiType.MEAL_CATEGORIES,
        }
    },

    computed: {
        ...mapGetters({
            mealCategory: mealType.getters.MEAL_CATEGORY,
            meal: mealType.getters.MEAL,
        }),
    },

    watch: {
        $route: {
            handler() {
                if (!this.$route.query.meal && this.meal.restaurant)
                    this.$store.dispatch(mealType.actions.SET, {})

                if (
                    !this.$route.query.meal &&
                    this.$route.query.meal_category
                ) {
                    RestApiCollection.get(this.apiCategory)
                        .get(this.$route.query.meal_category)
                        .then((res) => {
                            this.$store.dispatch(
                                mealType.actions.MEAL_CATEGORY,
                                res
                            )
                        })
                }

                if (
                    this.$route.query.meal &&
                    this.$route.query.meal != 'new' &&
                    !this.meal.restaurant
                ) {
                    RestApiCollection.get(this.api)
                        .get(this.$route.query.meal)
                        .then((res) => {
                            this.$store.dispatch(mealType.actions.SET, res)
                        })
                }
            },
            immediate: true,
        },
    },
}
</script>

<template>
    <v-row v-if="restaurant" class="fill-height">
        <v-col lg="4" md="6">
            <meal-category-menu :restaurant="restaurant"></meal-category-menu>
            <add-meal-category :restaurant="restaurant"></add-meal-category>
        </v-col>
        <v-col lg="8" md="6">
            <meal
                v-if="$route.query.meal"
                :value="meal"
                @tabError="$emit('tab-error', $event)"
            ></meal>
            <meal-category
                v-else-if="mealCategory && mealCategory.id"
                :restaurant="restaurant"
                :category="mealCategory"
            ></meal-category>
        </v-col>
    </v-row>
</template>
