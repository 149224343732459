<script>
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'

import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TextareaInput,
        AddressAutocompleteInput,
        AddressMapInput,
    },

    mixins: [PhoneNumberMixin, FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    computed: {
        requirement() {
            return this.model?.deliverySetting?.orditManagedDelivery
        },
    },
}
</script>

<template>
    <!-- PICKUP DETAILS TAB -->
    <v-tab-item
        key="pickup_details_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel
            v-if="model.pickupInformation"
            :title="$t('labels.pickup_details')"
        >
            <form-two-col-row>
                <template v-slot:col1>
                    <form-one-col-row>
                        <address-autocomplete-input
                            v-model="model.pickupInformation.fullAddress"
                            v-validate.immediate="requirement ? 'required' : ''"
                            v-tab-error:pickupDetails
                            :latitude.sync="model.pickupInformation.latitude"
                            :longitude.sync="model.pickupInformation.longitude"
                            :country.sync="model.pickupInformation.country"
                            :zip-code.sync="model.pickupInformation.postCode"
                            :town.sync="model.pickupInformation.city"
                            :street.sync="model.pickupInformation.street"
                            :house-number.sync="
                                model.pickupInformation.houseNumber
                            "
                            label="labels.address"
                            name="model.pickupInformation.fullAddress"
                            :error-messages="
                                errors.collect(
                                    'model.pickupInformation.fullAddress'
                                )
                            "
                            :data-vv-as="$t('labels.address')"
                        ></address-autocomplete-input>
                    </form-one-col-row>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <text-input
                                v-model="
                                    model.pickupInformation.contactFirstName
                                "
                                v-validate.immediate="
                                    requirement
                                        ? 'required|min:2|max:255|space'
                                        : 'min:2|max:255|space'
                                "
                                v-tab-error:pickupDetails
                                name="pickupDetails.contactFirstName"
                                label="labels.first_name"
                                :error-messages="
                                    errors.collect(
                                        'pickupDetails.contactFirstName'
                                    )
                                "
                                :data-vv-as="$t('labels.first_name')"
                            ></text-input>
                        </template>
                        <template v-slot:col2>
                            <text-input
                                v-model="
                                    model.pickupInformation.contactLastName
                                "
                                v-validate.immediate="
                                    requirement
                                        ? 'required|min:2|max:255|space'
                                        : 'min:2|max:255|space'
                                "
                                v-tab-error:pickupDetails
                                name="pickupDetails.contactLastName"
                                label="labels.last_name"
                                :error-messages="
                                    errors.collect(
                                        'pickupDetails.contactLastName'
                                    )
                                "
                                :data-vv-as="$t('labels.last_name')"
                            ></text-input>
                        </template>
                    </form-two-col-row>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <text-input
                                v-model="model.pickupInformation.contactPhone"
                                v-validate.immediate="
                                    requirement
                                        ? 'required|phonenumber'
                                        : 'phonenumber'
                                "
                                v-tab-error:pickupDetails
                                name="pickupInformation.contactPhone"
                                label="labels.phone"
                                :error-messages="
                                    errors.collect(
                                        'pickupInformation.contactPhone'
                                    )
                                "
                                :data-vv-as="$t('labels.phone')"
                                @blur="
                                    model.pickupInformation.contactPhone = formatPhoneNumber(
                                        model.pickupInformation.contactPhone
                                    )
                                "
                            ></text-input>
                        </template>
                        <template v-slot:col2>
                            <text-input
                                v-model="model.pickupInformation.contactEmail"
                                v-validate.immediate="
                                    requirement ? 'required|email' : 'email'
                                "
                                v-tab-error:pickupDetails
                                name="pickupInformation.contactEmail"
                                label="labels.email"
                                :error-messages="
                                    errors.collect(
                                        'pickupInformation.contactEmail'
                                    )
                                "
                                :data-vv-as="$t('labels.email')"
                            ></text-input>
                        </template>
                    </form-two-col-row>
                    <form-one-col-row>
                        <text-input
                            v-model="model.pickupInformation.contactCompany"
                            v-validate.immediate="
                                requirement
                                    ? 'required|min:2|max:255|space'
                                    : 'min:2|max:255|space'
                            "
                            v-tab-error:pickupDetails
                            name="pickupInformation.contactCompany"
                            label="labels.company"
                            :error-messages="
                                errors.collect(
                                    'pickupInformation.contactCompany'
                                )
                            "
                            :data-vv-as="$t('labels.company')"
                        ></text-input>
                    </form-one-col-row>
                    <form-one-col-row>
                        <textarea-input
                            v-model="model.pickupInformation.pickupInstructions"
                            v-tab-error:pickupDetails
                            name="pickupInformation.pickupInstructions"
                            label="labels.instructions"
                            :error-messages="
                                errors.collect(
                                    'pickupInformation.pickupInstructions'
                                )
                            "
                            :data-vv-as="$t('labels.instructions')"
                        ></textarea-input>
                    </form-one-col-row>
                </template>
                <template v-slot:col2>
                    <address-map-input
                        v-model="model.pickupInformation.fullAddress"
                        style="height: 300px"
                        update-address-details
                        name="model.pickupInformation.fullAddress.map"
                        :latitude.sync="model.pickupInformation.latitude"
                        :longitude.sync="model.pickupInformation.longitude"
                        :country.sync="model.pickupInformation.country"
                        :zip-code.sync="model.pickupInformation.postCode"
                        :town.sync="model.pickupInformation.city"
                        :street.sync="model.pickupInformation.street"
                        :house-number.sync="model.pickupInformation.houseNumber"
                    ></address-map-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel
            v-if="model.deliverySetting"
            :title="$t('labels.preparation_time')"
        >
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model.number="
                            model.deliverySetting.defaultPreparationTime
                        "
                        v-validate.immediate="
                            requirement
                                ? 'required|integer|min_value:0'
                                : 'integer|min_value:0'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.defaultPreparationTime"
                        label="labels.preparation_time_label"
                        :error-messages="
                            errors.collect(
                                'pickupInformation.defaultPreparationTime'
                            )
                        "
                        :data-vv-as="$t('labels.preparation_time_label')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </v-tab-item>
</template>
