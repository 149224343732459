<script>
import MealsInlineTable from '@/components/pages/meals/InlineTable'
import ImportMeals from '@/components/pages/meals/ImportMeals'
import ExportMeals from '@/components/pages/meals/ExportMeals'

export default {
    components: {
        MealsInlineTable,
        ImportMeals,
        ExportMeals,
    },

    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<template>
    <!-- MENU TAB -->
    <v-tab-item
        key="menu_tab"
        :disabled="!value['@id']"
        :transition="false"
        :reverse-transition="false"
    >
        <meals-inline-table
            :restaurant="value['@id']"
            @tab-error="$emit('tab-error', $event)"
        ></meals-inline-table>
        <portal v-if="!$route.query.meal && $route.query.tab == 5" to="footer">
            <import-meals class="mr-5" :restaurant="value['id']"></import-meals>
            <export-meals :restaurant="value['id']"></export-meals>
        </portal>
    </v-tab-item>
</template>
