<script>
import SelectApiClientType from '@/api/SelectApiClientType'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'

import Accordion from '@/components/elements/Accordion'
import DeliveryArea from '@/components/pages/restaurants/DeliveryArea'

import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import cloneDeep from 'lodash/cloneDeep'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        FormThreeColRow,
        TextInput,
        EntitySelectInput,
        AddressAutocompleteInput,
        AddressMapInput,
        DeliveryArea,
        Accordion,
    },

    mixins: [PhoneNumberMixin, FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    data() {
        return {
            selectApiClientType: SelectApiClientType,
            areaModel: {
                '@type': 'RestaurantArea',
                area: [],
                deliveryDelay: 0,
                deliveryMaxTime: 0,
                deliveryMinTime: 0,
                deliveryPrice: 0,
                deliveryPriceLimit: 0,
                minimumOrder: 0,
                deliveryRadius: null,
                isActive: true,
                name: null,
            },
            activeAccordionPanel: null,
        }
    },

    methods: {
        setArea(area, index) {
            if (area && area['@type']) this.$set(this.model.areas, index, area)
        },

        removeArea(index) {
            this.$delete(this.model.areas, index)
            this.$set(this, 'activeAccordionPanel', null)
        },

        addArea() {
            if (this.model.areas && Array.isArray(this.model.areas)) {
                this.$set(this.model, 'areas', [
                    ...this.model.areas,
                    cloneDeep(this.areaModel),
                ])
            } else {
                this.$set(this.model, 'areas', [cloneDeep(this.areaModel)])
            }
            this.$set(this, 'activeAccordionPanel', this.model.areas.length - 1)
        },
    },
}
</script>

<template>
    <!-- DELIVERY ZONES TAB -->
    <v-tab-item
        key="delivery_zones_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <!-- DELIVERY CONTACT -->
        <form-panel :title="$t('labels.contact_for_delivery')">
            <form-three-col-row v-if="model.billingInfo">
                <template v-slot:col1>
                    <!-- DELIVERY CONTACT NAME -->
                    <text-input
                        v-model="model.billingInfo.orderContactName"
                        v-validate.immediate="'space'"
                        v-tab-error:deliveryZones
                        name="billingInfo.orderContactName"
                        label="labels.name"
                        :error-messages="
                            errors.collect('billingInfo.orderContactName')
                        "
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- DELIVERY CONTACT PHONE -->
                    <text-input
                        v-model="model.billingInfo.orderContactPhone"
                        v-validate.immediate="'phonenumber'"
                        v-tab-error:deliveryZones
                        name="billingInfo.orderContactPhone"
                        label="labels.phone"
                        :error-messages="
                            errors.collect('billingInfo.orderContactPhone')
                        "
                        :data-vv-as="$t('labels.phone')"
                        @blur="
                            model.billingInfo.orderContactPhone = formatPhoneNumber(
                                model.billingInfo.orderContactPhone
                            )
                        "
                    ></text-input>
                </template>
                <template v-slot:col3>
                    <!-- DELIVERY CONTACT EMAIL -->
                    <text-input
                        v-model="model.billingInfo.orderContactEmail"
                        v-validate.immediate="'space'"
                        v-tab-error:deliveryZones
                        name="billingInfo.orderContactEmail"
                        label="labels.email"
                        :error-messages="
                            errors.collect('billingInfo.orderContactEmail')
                        "
                        :data-vv-as="$t('labels.email')"
                    ></text-input>
                </template>
            </form-three-col-row>
        </form-panel>

        <!-- RESTAURANT ADDRESS -->
        <form-panel :title="$t('labels.restaurant_address')">
            <form-two-col-row>
                <template v-slot:col1>
                    <address-autocomplete-input
                        v-model="model.location"
                        v-validate.immediate="'required'"
                        v-tab-error:deliveryZones
                        label="labels.address"
                        name="location"
                        :error-messages="errors.collect('location')"
                        :data-vv-as="$t('labels.address')"
                        :longitude.sync="model.longitude"
                        :latitude.sync="model.latitude"
                    ></address-autocomplete-input>
                </template>
                <template v-slot:col2>
                    <address-map-input
                        v-model="model.location"
                        v-tab-error:deliveryZones
                        style="height: 300px"
                        :longitude.sync="model.longitude"
                        :latitude.sync="model.latitude"
                        update-address-details
                        name="location"
                    ></address-map-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- DELIVERY AREA -->
        <form-panel :title="$t('labels.delivery_area')">
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="model.deliveryAreaType"
                        v-validate.immediate="''"
                        v-tab-error:deliveryZones
                        label="labels.delivery_area_type"
                        :data-vv-as="$t('labels.delivery_area_type')"
                        :entity="
                            selectApiClientType.RESTAURANT_DELIVERY_AREA_TYPE
                        "
                        name="deliveryAreaType"
                        autocomplete
                        :error-messages="errors.collect('deliveryAreaType')"
                        translate-items
                    ></entity-select-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <form-panel :title="$t('labels.delivery_areas')">
            <div class="row">
                <div class="col-md-9">
                    <accordion
                        v-if="model.areas && model.areas.length"
                        :panel="activeAccordionPanel"
                    >
                        <delivery-area
                            v-for="(area, index) in model.areas"
                            :key="area['@id']"
                            :name="area.name"
                            :value="area"
                            :model-object="model"
                            @input="setArea($event, index)"
                            @remove-area="removeArea(index)"
                            @tabError="tabErrorsContainer = $event"
                        ></delivery-area>
                    </accordion>
                </div>
                <div class="col-md-3">
                    <v-btn
                        class="ma-2"
                        outlined
                        color="success"
                        @click="addArea"
                    >
                        <v-icon left>add</v-icon> {{ $t('actions.add') }}
                    </v-btn>
                </div>
            </div>
        </form-panel>
    </v-tab-item>
</template>
