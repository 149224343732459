<script>
import FormPanel from '@/components/form/FormPanel'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'

import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    components: {
        FormPanel,
        EntitySelectInput,
    },

    data() {
        return {
            softwareModel: {
                '@type': 'RestaurantSoftwareSetting',
                isOpeningTimeChecked: false,
                openingDelay: null,
                closingDelay: null,
                username: null,
                password: null,
                softwareLimits: [],
            },
            model: null,
            selectApiClientType: SelectApiClientType,
        }
    },

    methods: {
        addSoftware() {
            const software = { ...this.softwareModel, software: this.model }
            this.$emit('added', software)
        },
    },
}
</script>

<template>
    <form-panel :title="$t('labels.add_software_setting')">
        <div class="d-flex align-center justify-space-between">
            <entity-select-input
                v-model="model"
                label="labels.softwares"
                :data-vv-as="$t('labels.softwares')"
                :entity="selectApiClientType.ORDERING_SOFTWARE"
                name="software"
                translate-items
            ></entity-select-input>
            <v-btn class="ma-2" outlined color="success" @click="addSoftware">
                <v-icon left>add</v-icon> {{ $t('actions.add') }}
            </v-btn>
        </div>
    </form-panel>
</template>
