<script>
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import PeriodInput from '@/components/form/Inputs/PeriodInput'
import Closures from '@/components/pages/restaurants/Closures'
import UpcomingClosures from '@/components/pages/restaurants/UpcomingClosures'
import TextInput from '@/components/form/Inputs/TextInput'
import PauseToday from '@/components/pages/restaurants/PauseToday'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import { ROLE_ADMIN } from '@/enum/roles'
import NotificationSwitch from '@/components/pages/restaurants/NotificationSwitch'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        DatePickerInput,
        NotificationSwitch,
        PauseToday,
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        PeriodInput,
        CheckboxInput,
        Closures,
        UpcomingClosures,
        TextInput,
    },

    mixins: [FormTabMixin, FormInput],
    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
            userRoles: authType.getters.GET_ROLES,
        }),
        canEdit() {
            return this.model.isOpeningHoursEditEnabled
        },
        isSuperAdmin() {
            return this.userRoles.indexOf(ROLE_ADMIN) !== -1
        },
    },
}
</script>

<template>
    <!-- OPEN HOURS TAB -->
    <v-tab-item
        key="open_hours_tab"
        :transition="false"
        :reverse-transition="false"
    >
        <!-- PAUSE RESTAURANT FOR TODAY -->
        <pause-today v-if="isRestaurantAdmin" :model="model"></pause-today>

        <!-- NOTIFICATION SWITCH -->
        <notification-switch v-if="isRestaurantAdmin"></notification-switch>

        <!-- PREORDER SETTINGS -->
        <form-panel
            v-if="!isRestaurantAdmin"
            :title="$t('labels.preorder_settings')"
        >
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- PREORDER ONLY -->
                    <checkbox-input
                        v-model="model.isPreorder"
                        v-tab-error:openHours
                        label="texts.this_restaurant_only_accept_preorders"
                        name="isPreorder"
                    ></checkbox-input>
                </template>
                <template v-slot:col2>
                    <!-- ORDER SUBMIT TIME -->
                    <date-picker-input
                        v-model="model.preorderBorderTime"
                        v-validate.immediate="''"
                        v-tab-error:openHours
                        label="texts.until_when_is_it_possible_to_submit_an_order"
                        :data-vv-as="
                            $t(
                                'texts.until_when_is_it_possible_to_submit_an_order'
                            )
                        "
                        name="preorderBorderTime"
                        :error-messages="errors.collect('preorderBorderTime')"
                        only-time
                    ></date-picker-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model.number="model.preorderDayBefore"
                        v-validate.immediate="'integer|min_value:0'"
                        v-tab-error:openHours
                        name="preorderDayBefore"
                        label="labels.notice_period"
                        :error-messages="errors.collect('preorderDayBefore')"
                        :data-vv-as="$t('labels.notice_period')"
                        type="number"
                    ></text-input>
                </template>
                <template v-slot:col2></template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- NOTICE PERIOD CALENDAR DAYS -->
                    <checkbox-input
                        v-model="model.isPreorderDiffCalendar"
                        v-tab-error:openHours
                        label="texts.notice_period_calculates_calendar_days_instead_of_opening_days"
                        name="isPreorderDiffCalendar"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <!-- OPEN HOURS -->
        <form-panel :title="$t('labels.open_hours_for_menu')">
            <form-two-col-row v-if="isSuperAdmin">
                <template v-slot:col1>
                    <checkbox-input
                        v-model="model.isOpeningHoursEditEnabled"
                        v-tab-error:businesshours
                        :error-messages="
                            errors.collect('isOpeningHoursEditEnabled')
                        "
                        label="labels.can_edit_opening_hours"
                        name="isOpeningHoursEditEnabled"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <period-input
                    v-model="model.openingDays"
                    :can-edit="model.isOpeningHoursEditEnabled || isSuperAdmin"
                    name="openingDays"
                ></period-input>
            </form-one-col-row>
        </form-panel>
        <form-panel
            v-if="canEdit || isSuperAdmin"
            :title="$t('labels.special_opening_hours')"
        >
            <closures
                v-model="model.specialClosures"
                name="closures"
            ></closures>
        </form-panel>
        <form-panel :title="$t('labels.upcoming_opening_hours')">
            <upcoming-closures
                v-model="model.specialClosures"
            ></upcoming-closures>
        </form-panel>
    </v-tab-item>
</template>
