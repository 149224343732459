<script>
import FormInput from '@/components/mixins/FormInput'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import SoftwareLimits from '@/components/pages/restaurants/SoftwareLimits'
import FormTabMixin from '@/components/mixins/FormTabMixin'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        SoftwareLimits,
    },

    mixins: [FormInput, FormTabMixin],

    props: {
        isDefault: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        propKey() {
            return this.isDefault ? 'orditSoftwareSetting' : 'softwareSetting'
        },
    },
}
</script>

<template>
    <div class="restaurant-software">
        <form-panel :title="$t('labels.order_transfer_rules')">
            <software-limits
                v-model="model"
                :name="model.software + 'software-setting'"
                @tabError="tabErrorsContainer = $event"
            ></software-limits>
        </form-panel>
        <form-panel :title="$t('labels.identification')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- ID 1 -->
                    <text-input
                        v-model="model.username"
                        v-validate="'space'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.username`"
                        label="labels.id_1"
                        :error-messages="errors.collect(`${propKey}.username`)"
                        :data-vv-as="$t('labels.id_1')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- ID 2 -->
                    <text-input
                        v-model="model.password"
                        v-validate="''"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.password`"
                        label="labels.id_2"
                        :error-messages="errors.collect(`${propKey}.password`)"
                        :data-vv-as="$t('labels.id_2')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel :title="$t('labels.time_settings')">
            <form-two-col-row>
                <!-- OPENING TIME CHECK -->
                <template v-slot:col1>
                    <checkbox-input
                        v-model="model.isOpeningTimeChecked"
                        v-validate="''"
                        v-tab-error:orderTransfer
                        label="texts.order_transfer_should_recognize_the_opening_time_of_the_restaurant"
                        name="isPreorderDiffCalendar"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- OPENING DELAY -->
                    <text-input
                        v-model.number="model.openingDelay"
                        v-validate="'integer'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.openingDelay`"
                        label="texts.order_transfer_open_time_delay"
                        :error-messages="
                            errors.collect(`${propKey}.openingDelay`)
                        "
                        :data-vv-as="$t('texts.order_transfer_open_time_delay')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- CLOSING DELAY -->
                    <text-input
                        v-model.number="model.closingDelay"
                        v-validate="'integer'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.closingDelay`"
                        label="texts.order_transfer_close_time_delay"
                        :error-messages="
                            errors.collect(`${propKey}.closingDelay`)
                        "
                        :data-vv-as="
                            $t('texts.order_transfer_close_time_delay')
                        "
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <div v-if="!isDefault" class="d-flex justify-end">
            <v-btn
                class="ma-2"
                outlined
                color="error"
                @click="$emit('remove-software', model)"
            >
                <v-icon left>remove</v-icon> {{ $t('actions.remove') }}
            </v-btn>
        </div>
    </div>
</template>
