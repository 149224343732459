<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MealCard from '@/components/pages/meals/MealCard'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'

import Draggable from 'vuedraggable'
import Overlay from '@/components/overlay/Overlay'

export default {
    components: { MealCard, Draggable, Overlay },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
        category: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            meals: [],
            api: RestApiType.MEAL,
            mapper: MapperType.MEAL_LIST,
            showOverlay: false,
        }
    },
    watch: {
        category: {
            handler() {
                this.getMeals()
            },
            immediate: true,
        },
    },

    methods: {
        getMeals() {
            RestApiCollection.get(this.api)
                .list({ category: this.category['@id'] })
                .then((res) => {
                    this.meals = MapperCollection.get(this.mapper).map(
                        res['hydra:member']
                    )
                })
        },
        onDragEnd(event) {
            if (
                !event.moved ||
                !event.moved.element ||
                !event.moved.element.id
            ) {
                this.$refs.iterator.updateItems()
            }

            const idx = this.meals[event.moved.newIndex].position

            this.showOverlay = true

            RestApiCollection.get(this.api)
                .moveToPosition(event.moved.element.id, idx)
                .finally(() => {
                    this.showOverlay = false
                    this.getMeals()
                })
        },
    },
}
</script>

<template>
    <overlay :show="showOverlay">
        <draggable
            class="row mt-5"
            :value="meals"
            handle=".handle-meal"
            @change="onDragEnd"
        >
            <v-col v-for="meal in meals" :key="meal.id" lg="6" md="12">
                <meal-card
                    class="handle-meal c-pointer"
                    :model="meal"
                    @saved="getMeals"
                ></meal-card>
            </v-col>
        </draggable>
    </overlay>
</template>
