<script>
import RestApiCollection from '@/api/RestApiCollection'
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: { ModalDialog },
    props: {
        api: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: 'red',
        },
        approveText: {
            type: String,
            default: null,
        },
        processing: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            dialog: false,
            inactivateEntity: 'only_today',
        }
    },

    methods: {
        toggleActivate(e) {
            e.stopPropagation()
            e.preventDefault()
            if (this.model.isActive) {
                this.dialog = true
            } else {
                RestApiCollection.get(this.api)
                    .activate(this.model.id)
                    .finally(() => {
                        this.$emit('saved')
                    })
            }
        },
        onApprove() {
            if (this.inactivateEntity === 'only_today') {
                RestApiCollection.get(this.api)
                    .inactivateForToday(this.model.id)
                    .finally(() => {
                        this.$emit('saved')
                        this.dialog = false
                    })
            } else {
                RestApiCollection.get(this.api)
                    .inactivate(this.model.id)
                    .finally(() => {
                        this.$emit('saved')
                        this.dialog = false
                    })
            }
        },
        onCancel() {},
    },
}
</script>
<template>
    <div>
        <v-chip
            :color="model.isActive ? 'error' : 'success'"
            small
            @click="toggleActivate"
        >
            {{
                model.isActive
                    ? $t('actions.inactivate')
                    : $t('actions.activate')
            }}
        </v-chip>
        <modal-dialog
            v-model="dialog"
            :title="title ? title : $t('labels.inactivate_for')"
            cancel-button
            :cancel-text="$t('actions.cancel')"
            :ok-text="approveText || this.$t('actions.inactivate')"
            :ok-color="approveColor"
            :show-overlay="processing"
            @cancel="onCancel"
            @ok="onApprove"
        >
            <v-container class="px-0" fluid>
                <div class="radio-group-row py-2">
                    <label class="radio">
                        <span>{{ $t('labels.inactivate_for_today') }}</span>
                        <input
                            v-model="inactivateEntity"
                            type="radio"
                            value="only_today"
                            name="ordercancel"
                        />
                    </label>
                </div>
                <div class="radio-group-row py-2">
                    <label class="radio">
                        <span>{{
                            $t('labels.inactivate_for_indefinitely')
                        }}</span>
                        <input
                            v-model="inactivateEntity"
                            type="radio"
                            value="indefinite_period"
                            name="ordercancel"
                        />
                    </label>
                </div>
            </v-container>
        </modal-dialog>
    </div>
</template>
