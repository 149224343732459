<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import FileInput from '@/components/form/Inputs/FileInput'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import mealType from '@/store/type/mealType'
import { mapMutations } from 'vuex'

export default {
    components: {
        ModalDialog,
        FileInput,
    },

    props: {
        restaurant: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            dialog: false,
            formData: null,
            disabled: true,
            importErrors: null,
        }
    },

    methods: {
        ...mapMutations({
            updateList: mealType.mutations.REFRESH_MEAL_CATEGORY_LIST,
        }),

        importMeal() {
            RestaurantsApiClient.import(this.restaurant, this.formData)
                .then(() => {
                    this.formData = null
                    this.dialog = false
                    this.updateList()
                })
                .catch((e) => {
                    this.importErrors = e.response.data.errors
                })
        },

        setFormData(file, name) {
            if (!file) {
                this.$set(this, 'formData', null)
                this.disabled = true
                this.importErrors = null
                return
            }
            if (!this.formData) this.formData = new FormData()
            this.formData.append(name, file)

            this.disabled =
                !this.formData ||
                !this.formData.has('categories') ||
                !this.formData.has('meals') ||
                !this.formData.has('option-groups')
        },
    },
}
</script>
<template>
    <div class="import-meals d-inline-block">
        <v-btn color="success" @click="dialog = true">
            {{ $t('actions.import') }}
        </v-btn>
        <modal-dialog
            v-model="dialog"
            :title="$t('labels.import_meals')"
            cancel-button
            :max-width="480"
            :ok-disabled="disabled"
            @ok="importMeal"
            @cancel="setFormData"
        >
            <div v-if="dialog">
                <file-input
                    name="categories"
                    accepted-files="text/csv"
                    label="labels.meal_categories"
                    :is-form-data="true"
                    @input="setFormData($event, 'categories')"
                ></file-input>
                <file-input
                    name="meals"
                    accepted-files="text/csv"
                    label="labels.meals"
                    :is-form-data="true"
                    @input="setFormData($event, 'meals')"
                ></file-input>
                <file-input
                    name="options"
                    accepted-files="text/csv"
                    label="labels.meal_options"
                    :is-form-data="true"
                    @input="setFormData($event, 'option-groups')"
                ></file-input>
                <ul v-if="importErrors" class="import-errors">
                    <li v-for="(csvErrors, csv) in importErrors" :key="csv">
                        <h4 class="mt-0 mb-2">
                            {{ csv + ':' }}
                        </h4>
                        <ul>
                            <li
                                v-for="(error, rowIndex) in csvErrors"
                                :key="rowIndex"
                            >
                                {{ rowIndex + ':' }}
                                <ul>
                                    <li
                                        v-for="(errorType, columnKey) in error"
                                        :key="columnKey"
                                    >
                                        {{ columnKey }}
                                        <ul>
                                            <li
                                                v-for="message in errorType"
                                                :key="message.message"
                                            >
                                                {{ message.message }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </modal-dialog>
    </div>
</template>

<style lang="scss">
.import-errors {
    max-height: 200px;
    overflow-y: scroll;
    background-color: var(--v-g-f0-base);
    padding: 12px;
    border-radius: 3px;
    font-size: 12px;
    margin: 0;

    li {
        font-size: 12px;
    }
}
</style>
