<script>
import RestApiType from '@/api/RestApiType'
import DataIterator from '@/components/table/DataIterator'
import Draggable from 'vuedraggable'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'
import ModalActivateForToday from '@/components/pages/meals/ModalActivateForToday'

export default {
    components: {
        DataIterator,
        Draggable,
        ModalActivateForToday,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            api: RestApiType.MEAL_OPTION_GROUP,
            listMapper: MapperType.MEAL_OPTION_GROUP,
            showOverlay: false,
        }
    },

    computed: {
        ...mapGetters({
            mealOptionGroup: mealType.getters.MEAL_OPTION_GROUP,
        }),
    },

    mounted() {
        this.$store.subscribe((mutation) => {
            if (
                mutation.type ==
                mealType.mutations.REFRESH_MEAL_OPTION_GROUP_LIST
            ) {
                this.$refs.iterator.updateItems()
            }
        })
    },

    methods: {
        onDragEnd(event, items) {
            if (
                !event.moved ||
                !event.moved.element ||
                !event.moved.element.id
            ) {
                if (this.$refs && this.$refs.iterator)
                    this.$refs.iterator.updateItems()
            }

            const idx = items[event.moved.newIndex].position

            this.showOverlay = true

            RestApiCollection.get(this.api)
                .moveToPosition(event.moved.element.id, idx)
                .finally(() => {
                    this.showOverlay = false
                    this.$refs.iterator.updateItems()
                })
        },

        inactiveLabel: function (inactiveForToday) {
            return inactiveForToday
                ? `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_today'
                  )})`
                : `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_indefinitely'
                  )})`
        },

        setGroup(item) {
            if (this.$route.query.meal_option_group)
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        meal_option_group: undefined,
                    },
                })

            RestApiCollection.get(this.api)
                .get(item.id)
                .then((res) => {
                    this.$store
                        .dispatch(mealType.actions.MEAL_OPTION_GROUP, res)
                        .then(() => {
                            this.$nextTick(() => {
                                let docViewTop = window.scrollY
                                let docViewBottom =
                                    docViewTop + window.innerHeight

                                let elem = document.getElementById(
                                    'meal-option-group-' + res.id
                                )
                                let elemTop = elem.offsetTop
                                let elemBottom = elemTop + elem.offsetHeight

                                let inIt =
                                    elemBottom <= docViewBottom &&
                                    elemTop >= docViewTop
                                if (!inIt)
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })

                                if (
                                    this.$route.query.meal_option_group !=
                                    res.id
                                )
                                    this.$router.push({
                                        query: {
                                            ...this.$route.query,
                                            meal_option_group: res.id,
                                        },
                                    })
                            })
                        })
                })
        },
    },
}
</script>

<template>
    <data-iterator
        v-if="restaurant"
        ref="iterator"
        :api="api"
        class="dandd-iterator"
        :sort-by="null"
        :sort-desc="null"
        :filters="{ restaurant: restaurant }"
        :mapper="listMapper"
        :show-overlay="showOverlay"
        disable-query-params
        disable-pagination
    >
        <template slot-scope="{ items }">
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <h2 class="mb-4">
                            {{ $t('labels.meal_option_groups') }}
                        </h2>
                    </v-list-item-content>
                </v-list-item>
                <draggable
                    :value="items"
                    handle=".handle"
                    @change="onDragEnd($event, items)"
                >
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="item['@id']"
                        class="c-pointer show-item-on-hover"
                        :class="{ active: item.id == mealOptionGroup.id }"
                        @click="setGroup(item)"
                    >
                        <v-list-item-title class="handle">
                            <v-icon> drag_indicator </v-icon>
                            {{ index + 1 + '. ' + item.name }}
                            <v-list-item-content
                                v-if="!item.isActive"
                                class="py-0"
                            >
                                <v-list-item-title class="ml-10">
                                    <span
                                        class="text-body-2 red--text font-italic"
                                    >
                                        {{
                                            inactiveLabel(item.inactivatedUntil)
                                        }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item-title>
                        <modal-activate-for-today
                            class="item-on-hover activate-button"
                            :api="api"
                            :model="item"
                            @saved="$refs.iterator.updateItems(true, true)"
                        ></modal-activate-for-today>
                    </v-list-item>
                </draggable>
            </v-list>
        </template>
    </data-iterator>
</template>
