<script>
import MealOptionForm from '@/components/pages/meals/mealOptions/MealOptionForm'

export default {
    components: {
        MealOptionForm,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
        mealOptionGroup: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            model: {
                name: null,
                restaurant: this.restaurant,
                isActive: true,
                parentGroup: this.mealOptionGroup,
            },
            show: false,
        }
    },

    methods: {
        toggleShow() {
            this.show = !this.show
            this.model = {
                name: null,
                restaurant: this.restaurant,
                isActive: true,
                parentGroup: this.mealOptionGroup,
                deliveryUnit: 0,
            }
        },
    },
}
</script>

<template>
    <div class="add-meal-category mt-4 px-5">
        <v-btn class="mb-5" @click="toggleShow">
            <v-icon left>add</v-icon>
            {{ $t('labels.add_meal_option') }}
        </v-btn>
        <meal-option-form
            v-if="show"
            v-model="model"
            @cancel="toggleShow"
            @saved="toggleShow"
            @tabError="$emit('tab-error', $event)"
        ></meal-option-form>
    </div>
</template>
