<script>
import FormTabs from '@/components/form/FormTabs'
import FormTab from '@/components/form/FormTab'
import DeliveryZones from './deliverySettings/DeliveryZones'
import DeliveryRules from './deliverySettings/DeliveryRules'
import PickupDetails from './deliverySettings/PickupDetails'

import FormTabMixin from '@/components/mixins/FormTabMixin'

export default {
    components: {
        FormTabs,
        FormTab,
        DeliveryZones,
        DeliveryRules,
        PickupDetails,
    },
    mixins: [FormTabMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        tabs() {
            return [
                {
                    name: 'deliveryZones',
                    title: this.$t('labels.delivery_zones'),
                },
                {
                    name: 'pickupDetails',
                    title: this.$t('labels.pickup_details'),
                },
                {
                    name: 'deliveryRules',
                    title: this.$t('labels.delivery_rules'),
                },
            ]
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.subTab) || 0
            },
            set(val) {
                this.$router.replace({
                    query: { subTab: val, tab: this.$route.query.tab },
                })
            },
        },
        tabErrors() {
            const tabErrors = {}
            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }
                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })
            return tabErrors
        },
    },

    methods: {
        getTabError(val) {
            this.tabErrorsContainer = {
                ...this.tabErrorsContainer,
                ...val,
            }

            let subTabErrors = {}

            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                subTabErrors = {
                    ...subTabErrors,
                    ...this.tabErrorsContainer[tabName],
                }
            })

            this.$emit('tabError', {
                deliverySettings: subTabErrors,
            })
        },
        onInput(val) {
            this.$emit('input', val)
        },
    },
}
</script>

<template>
    <v-tab-item
        key="delivery_settings_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-tabs v-model="activeTab" class="pb-16">
            <form-tab
                v-for="tab in tabs"
                :key="tab.name"
                :tab-errors="tabErrors"
                :name="tab.name"
                :title="tab.title"
                :disabled="tab.disabled ? true : false"
            ></form-tab>

            <v-tabs-items v-model="activeTab">
                <delivery-zones
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></delivery-zones>
                <pickup-details
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></pickup-details>
                <delivery-rules
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></delivery-rules>
            </v-tabs-items>
        </form-tabs>
    </v-tab-item>
</template>
