<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import DeliveryMethods from './DeliveryMethods'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        TextInput,
        EntitySelectInput,
        CheckboxInput,
        DeliveryMethods,
        DatePickerInput,
    },

    mixins: [FormInput, FormTabMixin],

    data() {
        return {
            selectApiClientType: SelectApiClientType,
        }
    },

    methods: {
        setRule(val, propKey, idx) {
            this.$emit('set-rule', {
                value: val,
                propKey: propKey,
                idx: idx,
            })
        },

        add() {
            this.$emit('added', {
                distanceFrom: this.model.distanceFrom,
                distanceTo: this.model.distanceTo,
                deliveryUnitFrom: null,
                deliveryUnitTo: null,
                vehicleType: null,
                numberOfVehicle: 1,
                thermalBagNeeded: false,
                thermalBoxNeeded: false,
                deliveryMethods: [{ method: null, backup: false }],
            })
        },

        addMethod(deliveryMethods) {
            deliveryMethods.push({ method: null, backup: false })
        },
    },
}
</script>
<template>
    <div class="delivery-rule">
        <div class="row">
            <div class="col-md-4">
                <text-input
                    v-validate.immediate="'required|integer|min_value:0'"
                    v-tab-error:deliveryRules
                    :value="model.distanceFrom"
                    :name="'deliveryRule.distanceFrom'"
                    label="labels.distance_from"
                    :error-messages="
                        errors.collect('deliveryRule.distanceFrom')
                    "
                    :data-vv-as="$t('labels.distance_from')"
                    @input="
                        setRule(
                            isNaN(parseInt($event)) ? null : parseInt($event),
                            'distanceFrom',
                            model.rules.map((el) => el.idx)
                        )
                    "
                ></text-input>
            </div>
            <div class="col-md-4">
                <text-input
                    v-validate.immediate="'min_value:0|integer'"
                    v-tab-error:deliveryRules
                    :value="model.distanceTo"
                    name="deliveryRule.distanceTo"
                    label="labels.distance_to"
                    :error-messages="errors.collect('deliveryRule.distanceTo')"
                    :data-vv-as="$t('labels.distance_to')"
                    @input="
                        setRule(
                            isNaN(parseInt($event)) ? null : parseInt($event),
                            'distanceTo',
                            model.rules.map((el) => el.idx)
                        )
                    "
                ></text-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <date-picker-input
                    ref="timeFrom"
                    v-model="model.timeFrom"
                    v-validate.immediate="'date_format:HH:mm'"
                    v-tab-error:deliveryRules
                    label="labels.delivery_rule_time_from"
                    name="deliveryRule.timeFrom"
                    :error-messages="errors.collect('deliveryRule.timeFrom')"
                    :data-vv-as="$t('labels.delivery_rule_time_from')"
                    only-time
                    @input="
                        setRule(
                            isNaN(parseInt($event)) ? null : parseInt($event),
                            'timeFrom',
                            model.rules.map((el) => el.idx)
                        )
                    "
                ></date-picker-input>
            </div>
            <div class="col-md-4">
                <date-picker-input
                    v-model="model.timeTo"
                    v-validate.immediate="'date_format:HH:mm'"
                    v-tab-error:deliveryRules
                    label="labels.delivery_rule_time_to"
                    name="deliveryRule.timeTo"
                    :error-messages="errors.collect('deliveryRule.timeTo')"
                    :data-vv-as="$t('labels.delivery_rule_time_to')"
                    only-time
                    @input="
                        setRule(
                            isNaN(parseInt($event)) ? null : parseInt($event),
                            'timeTo',
                            model.rules.map((el) => el.idx)
                        )
                    "
                ></date-picker-input>
            </div>
        </div>
        <div v-for="(rule, index) in model.rules" :key="rule.idx" class="row">
            <div class="col-12">
                <hr />
                <h4 class="mt-3">{{ $t('labels.units') }}</h4>
            </div>
            <div class="col-1">
                <h4>#{{ index + 1 }}</h4>
            </div>
            <div class="col-11">
                <div class="row">
                    <div class="col-md-6">
                        <text-input
                            v-validate.immediate="
                                'required|integer|min_value:0'
                            "
                            v-tab-error:deliveryRules
                            :value="rule.deliveryUnitFrom"
                            :name="'deliveryRule.deliveryUnitFrom' + rule.idx"
                            label="labels.delivery_unit_from"
                            :error-messages="
                                errors.collect(
                                    'deliveryRule.deliveryUnitFrom' + rule.idx
                                )
                            "
                            :data-vv-as="$t('labels.delivery_unit_from')"
                            @input="
                                setRule(
                                    isNaN(parseInt($event))
                                        ? null
                                        : parseInt($event),

                                    'deliveryUnitFrom',
                                    rule.idx
                                )
                            "
                        ></text-input>
                    </div>
                    <div class="col-md-6">
                        <text-input
                            v-validate.immediate="'integer'"
                            v-tab-error:deliveryRules
                            :value="rule.deliveryUnitTo"
                            :name="'deliveryRule.deliveryUnitTo' + rule.idx"
                            label="labels.delivery_unit_to"
                            :error-messages="
                                errors.collect(
                                    'deliveryRule.deliveryUnitTo' + rule.idx
                                )
                            "
                            :data-vv-as="$t('labels.delivery_unit_to')"
                            @input="
                                setRule(
                                    isNaN(parseInt($event))
                                        ? null
                                        : parseInt($event),

                                    'deliveryUnitTo',
                                    rule.idx
                                )
                            "
                        ></text-input>
                    </div>
                    <div class="col-md-4">
                        <text-input
                            v-validate.immediate="
                                'required|integer|min_value:1|max_value:2'
                            "
                            v-tab-error:deliveryRules
                            :value="rule.numberOfVehicle"
                            :name="'deliveryRule.numberOfVehicle' + rule.idx"
                            label="labels.number_of_vehicle"
                            :error-messages="
                                errors.collect(
                                    'deliveryRule.numberOfVehicle' + rule.idx
                                )
                            "
                            :data-vv-as="$t('labels.number_of_vehicle')"
                            @input="
                                setRule(
                                    isNaN(parseInt($event))
                                        ? null
                                        : parseInt($event),

                                    'numberOfVehicle',
                                    rule.idx
                                )
                            "
                        ></text-input>
                    </div>
                    <div class="col-md-4">
                        <entity-select-input
                            v-validate.immediate="'required'"
                            v-tab-error:deliveryRules
                            :value="rule.vehicleType"
                            label="labels.vehicle_type"
                            :data-vv-as="$t('labels.vehicle_type')"
                            :entity="selectApiClientType.VEHICLE_TYPE"
                            :name="'deliveryRule.vehicleType' + rule.idx"
                            translate-items
                            :error-messages="
                                errors.collect(
                                    'deliveryRule.vehicleType' + rule.idx
                                )
                            "
                            @input="setRule($event, 'vehicleType', rule.idx)"
                        ></entity-select-input>
                    </div>
                    <div class="col-md-12">
                        <h4>
                            {{ $t('labels.delivery_methods') }}
                        </h4>
                        <delivery-methods
                            :value="rule.deliveryMethods"
                            :index="index"
                            @remove="rule.deliveryMethods.splice($event)"
                            @tabError="$emit('tabError', $event)"
                        ></delivery-methods>
                        <div class="row">
                            <div class="col-md-12">
                                <v-btn
                                    class="ma-2"
                                    outlined
                                    color="success"
                                    @click="addMethod(rule.deliveryMethods)"
                                >
                                    <v-icon left>add</v-icon>
                                    {{ $t('actions.add') }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <checkbox-input
                            v-tab-error:deliveryRules
                            :value="rule.thermalBagNeeded"
                            name="deliveryRule.thermalBagNeeded"
                            label="labels.thermal_bag_needed"
                            @input="
                                setRule($event, 'thermalBagNeeded', rule.idx)
                            "
                        ></checkbox-input>
                    </div>
                    <div class="col-md-4">
                        <checkbox-input
                            v-tab-error:deliveryRules
                            :value="rule.thermalBoxNeeded"
                            name="deliveryRule.thermalBoxNeeded"
                            label="labels.thermal_box_needed"
                            @input="
                                setRule($event, 'thermalBoxNeeded', rule.idx)
                            "
                        ></checkbox-input>
                    </div>
                    <div class="col-md-2">
                        <v-btn
                            class="ma-2"
                            outlined
                            color="error"
                            @click="$emit('remove')"
                        >
                            <v-icon left>remove</v-icon>
                            {{ $t('actions.remove') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <v-btn class="ma-2" outlined color="success" @click="add">
                    <v-icon left>add</v-icon> {{ $t('actions.add') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>
