<template lang="pug">
    .markdown-input
        label(v-if="label" :for="'markdownEditor' + id") {{ labelText }}
        .flex.flex-wrap
            v-btn(v-for="operation in functions" :key="`operation-${operation.type}`" @click="apply(operation.variable, operation.type)" :input-value="$data[operation.variable]" color="g-73" fab icon tile x-small)
                v-icon {{ operation.icon }}
        .textfield(
            :id="'markdownEditor' + id"
            :class="{'focus': focus}"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @keypress.13.33.34.35.36.37.38.39.40="control"
            @click="control"
            v-html="innerValue"
            ref="textfield"
            contenteditable)

</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import { Marked } from '@ts-stack/markdown'
import TurndownService from 'turndown'

export default {
    name: 'MarkdownInput',

    mixins: [ErrorMsgInputMixin],

    props: {
        value: String,
    },

    data() {
        return {
            innerValue: Marked.parse(this.value) || '',
            focus: false,
            id: null,
            functions: [
                { icon: 'format_bold', type: 'bold', variable: 'isBold' },
                { icon: 'format_italic', type: 'italic', variable: 'isItalic' },
                {
                    icon: 'format_list_numbered',
                    type: 'insertOrderedList',
                    variable: 'isOList',
                },
                {
                    icon: 'format_list_bulleted',
                    type: 'insertUnorderedList',
                    variable: 'isUList',
                },
            ],
        }
    },

    created() {
        this.functions.forEach((operation) => {
            this.$data[operation.variable] = false
        })
    },

    mounted() {
        this.id = this._uid
    },

    methods: {
        getState(type) {
            const state = document.queryCommandState(type)
            return state ? state : undefined
        },
        setFocus() {
            this.$refs.textfield.focus()
        },
        onFocus() {
            this.focus = true
        },
        onBlur() {
            this.focus = false
        },
        control() {
            this.isBold = this.getState('bold')
            this.isItalic = this.getState('italic')
            this.isUList = this.getState('insertUnorderedList')
            this.isOList = this.getState('insertOrderedList')
        },
        onInput(event) {
            const turndown = new TurndownService({
                emDelimiter: '_',
                linkStyle: 'inlined',
                headingStyle: 'atx',
            })
            this.$emit('input', turndown.turndown(event.target.innerHTML))
        },
        apply(boolean, type) {
            this.setFocus()
            document.execCommand(type)
            this.$data[boolean] = this.getState(type)
            this.setFocus()
        },
    },
}
</script>
<style lang="scss">
.markdown-input {
    margin-bottom: 30px;

    .textfield {
        border-top: 1px solid rgba(0, 0, 0, 0.42);
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        color: rgba(0, 0, 0, 0.87);
        padding: 5px;
        transition: border-bottom-color var(--transition-speed);
        outline: none;

        &.focus {
            border-bottom-color: rgba(0, 0, 0, 0.87);
        }
    }
}
</style>
