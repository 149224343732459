<script>
import EditMealCategory from '@/components/pages/meals/mealCategory/EditMealCategory'
import MealList from '@/components/pages/meals/MealList'
import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'

export default {
    components: {
        EditMealCategory,
        MealList,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
        category: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },

    methods: {
        createMeal() {
            let newMeal = {
                restaurant: this.restaurant,
                category: this.category['@id'],
                isActive: false,
            }
            if (this.isRestaurantAdmin) this.$set(newMeal, 'deliveryUnit', 0)

            this.$store.dispatch(mealType.actions.SET, newMeal).then(() => {
                this.$router.push({
                    //name: this.$route.name,
                    query: { ...this.$route.query, meal: 'new' },
                })
            })
        },
    },
}
</script>
<template>
    <div class="meal-category">
        <div :id="'meal-category-' + category.id">
            <div class="d-flex justify-space-between align-center">
                <h2 class="my-4">
                    {{ category.name }}
                </h2>
            </div>
            <edit-meal-category></edit-meal-category>
            <meal-list
                :category="category"
                :restaurant="restaurant"
            ></meal-list>
            <v-btn class="mt-5" @click="createMeal">
                <v-icon left>add</v-icon>
                {{ $t('labels.add_new_meal') }}
            </v-btn>
        </div>
    </div>
</template>
