<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import authType from '@/store/type/authType'
import { EventBus } from '@/plugins/events'

export default {
    name: 'NotificationSwitch',
    components: { CheckboxInput },
    data() {
        return {
            loading: false,
            counter: 0,
        }
    },
    computed: {
        ...mapGetters({
            notificationRegistered: authType.getters.GET_NOTIFICATION_STATE,
            notificationPermissionGranted:
                configType.getters.NOTIFICATION_PERMISSION_GRANTED,
        }),
        blockedNotification() {
            return this.counter >= 3
        },
    },
    methods: {
        setPermission() {
            if (!this.loading) {
                this.loading = true
                if (
                    this.notificationPermissionGranted &&
                    this.notificationRegistered
                ) {
                    this.$store
                        .dispatch(authType.actions.UNREGISTER_NOTIFICATIONS)
                        .then(() => {
                            this.$cookies.set('notificationOffByUser', true)
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.loading = false
                            }, 1000)
                        })
                        .catch((e) => {
                            // eslint-disable-next-line no-console
                            console.log(e)
                        })
                } else {
                    Notification.requestPermission()
                        .then((res) => {
                            this.$store
                                .dispatch(
                                    configType.actions
                                        .SET_NOTIFICATION_PERMISSION,
                                    res === 'granted'
                                )
                                .then(() => {
                                    if (!this.notificationPermissionGranted) {
                                        setTimeout(() => {
                                            this.counter++
                                            this.loading = false
                                        }, 1500)
                                    } else {
                                        this.$store
                                            .dispatch(
                                                authType.actions
                                                    .REGISTER_NOTIFICATIONS
                                            )
                                            .then(() => {
                                                this.$cookies.remove(
                                                    'notificationOffByUser'
                                                )
                                                EventBus.$emit(
                                                    'notificationsOn'
                                                )
                                            })
                                            .finally(() => {
                                                this.loading = false
                                            })
                                            .catch((e) => {
                                                // eslint-disable-next-line no-console
                                                console.log(e)
                                            })
                                    }
                                })
                        })
                        .catch(() => {
                            this.loading = false
                        })
                }
            }
        },
    },
}
</script>

<template lang="pug">
v-card.notification-switch(elevation="0")
    v-card-text
        v-btn.mb-3(:color="notificationPermissionGranted && notificationRegistered ? 'warning' : 'primary'" :loading="loading" :disabled="blockedNotification" @click="setPermission")
            template(v-if="notificationPermissionGranted && notificationRegistered") {{$t('labels.turn_off_notifications')}}
            template(v-else) {{$t('labels.turn_on_notifications')}}

        v-fade-transition(mode="out-in")
            p.warning--text(v-if="blockedNotification") {{ $t('texts.notification_blocked_in_browser') }}
            p.g-73--text(v-else) {{ $t('texts.not_receive_order_until_notification_on') }}
</template>

<style scoped lang="scss"></style>
