<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import Accordion from '@/components/elements/Accordion'
import DeliveryRule from './DeliveryRule'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: { Accordion, DeliveryRule, TextInput },

    mixins: [FormInput, FormTabMixin],

    props: {
        value: {
            type: null,
            default: null,
        },

        enabled: {
            type: Boolean,
        },
    },

    computed: {
        computedRules() {
            let rules = []
            if (this.model?.length)
                for (let i = 0; i < this.model.length; i++) {
                    const idx = rules.findIndex((el) => {
                        return el.distanceFrom === this.model[i].distanceFrom
                    })

                    if (
                        idx !== -1 &&
                        rules[idx].distanceTo === this.model[i].distanceTo
                    ) {
                        if (rules[idx].rules?.length) {
                            rules[idx].rules.push({ ...this.model[i], idx: i })
                        } else {
                            rules[idx].rules = [{ ...this.model[i], idx: i }]
                        }
                    } else {
                        rules.push({
                            distanceFrom: this.model[i].distanceFrom,
                            distanceTo: this.model[i].distanceTo,
                            rules: [{ ...this.model[i], idx: i }],
                            idx: i,
                        })
                    }
                }
            return rules
        },

        rulesLength() {
            return this.model?.length ? this.model.length : 0
        },

        hasStart() {
            return this.computedRules?.length
                ? this.computedRules.findIndex((el) => {
                      return el.distanceFrom === 0
                  }) !== -1
                    ? 1
                    : 0
                : null
        },

        hasEnd() {
            return this.computedRules?.length
                ? this.computedRules.findIndex((el) => {
                      return !el.distanceTo
                  }) !== -1
                    ? 1
                    : 0
                : null
        },
    },

    watch: {
        hasStart() {
            this.resetErrors('deliveryRule.start')
        },

        hasEnd() {
            this.resetErrors('deliveryRule.end')
        },

        rulesLength() {
            this.resetErrors('deliveryRule.length')
        },
    },

    methods: {
        setRule(obj) {
            if (Array.isArray(obj.idx))
                for (let i = 0; i < obj.idx.length; i++)
                    this.$set(this.model[obj.idx[i]], obj.propKey, obj.value)
            else this.$set(this.model[obj.idx], obj.propKey, obj.value)
        },

        removeRule(idx) {
            this.model.splice(idx, 1)

            this.resetErrors()
        },

        getAccordionName(rule) {
            return `${rule.distanceFrom}${
                rule.distanceTo ? ' - ' + rule.distanceTo : ''
            }`
        },

        resetErrors(field) {
            this.$nextTick(() => {
                this.$validator.validate(field)
            })
        },
    },
}
</script>
<template>
    <div class="delivery-rules">
        <accordion v-if="computedRules && computedRules.length" :eager="true">
            <delivery-rule
                v-for="(rule, index) in computedRules"
                ref="deliveryRule"
                :key="index"
                :value="rule"
                :name="getAccordionName(rule)"
                @set-rule="setRule"
                @remove="removeRule(index)"
                @added="$emit('added', $event)"
                @tabError="$emit('tabError', $event)"
            ></delivery-rule>
        </accordion>
        <div class="my-5">
            <div
                v-if="enabled && !rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_delivery_rules') }}
            </div>
            <div
                v-if="enabled && !hasStart && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_distance_start') }}
            </div>
            <div
                v-if="enabled && !hasEnd && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_distance_end') }}
            </div>
        </div>
        <div class="hide-helper-inputs">
            <text-input
                ref="start"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasStart"
                label="labels.required_distance_start"
                name="deliveryRule.start"
                :error-messages="errors.collect('deliveryRule.start')"
                :data-vv-as="$t('labels.required_distance_start')"
            ></text-input>
            <text-input
                ref="end"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasEnd"
                label="labels.required_distance_end"
                name="deliveryRule.end"
                :error-messages="errors.collect('deliveryRule.end')"
                :data-vv-as="$t('labels.required_distance_end')"
            ></text-input>
            <text-input
                ref="length"
                v-validate.immediate="
                    enabled ? 'required|integer|min_value:1' : ''
                "
                v-tab-error:deliveryRules
                :value="rulesLength"
                label="labels.required_delivery_rules"
                name="deliveryRule.length"
                :error-messages="errors.collect('deliveryRule.length')"
                :data-vv-as="$t('labels.required_delivery_rules')"
            ></text-input>
        </div>
    </div>
</template>
