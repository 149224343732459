<script>
import Allergen from '@/components/pages/meals/Allergen'
import mealType from '@/store/type/mealType'
import ModalActivateForToday from '@/components/pages/meals/ModalActivateForToday'

import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import { Marked } from '@ts-stack/markdown'

export default {
    components: { Allergen, ModalActivateForToday },

    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            api: RestApiType.MEAL,
            mapper: MapperType.MEAL_FORM,
        }
    },

    computed: {
        allergens() {
            const allergens = this.model.allergens.map((el) => {
                return { name: el, type: 'allergens' }
            })
            const suitableFor = this.model.suitableFor.map((el) => {
                return { name: el, type: 'allergens_suitability' }
            })
            const additional = this.model.additional.map((el) => {
                return { name: el, type: 'allergens_additional' }
            })
            return [...allergens, ...suitableFor, ...additional]
        },
        description() {
            return this.model?.description
                ? Marked.parse(this.model.description)
                : null
        },
    },

    methods: {
        setMeal() {
            this.$nextTick(() => {
                this.$store
                    .dispatch(mealType.actions.SET, this.model)
                    .then(() => {
                        this.$router.push({
                            query: {
                                ...this.$route.query,
                                meal: this.model.id,
                            },
                        })
                    })
            })
        },

        inactiveLabel: function (inactiveForToday) {
            return inactiveForToday
                ? `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_today'
                  )})`
                : `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_indefinitely'
                  )})`
        },

        setIsActive(val) {
            const entity = MapperCollection.get(this.mapper).toEntity(
                this.model
            )

            RestApiCollection.get(this.api).update(
                {
                    ...entity,
                    isActive: val,
                },
                this.model.id
            )
        },
    },
}
</script>

<template>
    <v-card
        class="meal-card show-item-on-hover"
        :class="{ disabled: !model.isActive }"
    >
        <div class="mb-5">
            <div class="d-flex">
                <h4 class="meal-name line-clamp">{{ model.name }}</h4>
                <modal-activate-for-today
                    class="item-on-hover ml-auto"
                    :api="api"
                    :model="model"
                    @saved="$emit('saved')"
                ></modal-activate-for-today>
                <v-btn class="ml-2" color="g-73" small icon @click="setMeal">
                    <v-icon small>edit</v-icon>
                </v-btn>
            </div>
            <p
                v-if="!model.isActive"
                class="text-body-2 mb-0 red--text font-italic"
            >
                {{ inactiveLabel(model.inactivatedUntil) }}
            </p>
        </div>

        <div
            v-if="description"
            class="meal-description line-clamp mb-4"
            :inner-html.prop="description"
        ></div>
        <div class="d-flex justify-space-between">
            <span class="text-body-2">
                <template v-if="model.discountPrice">
                    <span class="red--text mr-3">{{
                        model.discountPrice | currency
                    }}</span>
                    <span class="text-decoration-line-through">{{
                        model.price | currency
                    }}</span>
                </template>
                <template v-else>
                    {{ model.price | currency }}
                </template>
            </span>

            <div class="d-flex">
                <allergen
                    v-for="allergen in allergens"
                    :key="allergen.name"
                    class="mx-1"
                    :model="allergen"
                ></allergen>
            </div>
        </div>
    </v-card>
</template>
<style lang="scss">
.meal-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--v-g-f0-base);
    padding: 12px 16px;
    background-color: var(--v-white-base);

    &.disabled {
        background-color: var(--v-g-f3-base);
    }

    .meal-name {
        -webkit-line-clamp: 2;
        max-height: rem(40px);
    }

    .meal-description {
        -webkit-line-clamp: 2;
        max-height: rem(32px);
        font-size: 12px;
        margin-top: auto;
        //color: $black-73;
    }

    .meal-is-active {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .meal-price {
        position: relative;
    }

    &.show-item-on-hover {
        .item-on-hover.activate-button {
            right: 16px;
            bottom: 12px;
            top: auto;
        }
        &:hover {
            .meal-price {
                span {
                    visibility: hidden;
                }
            }
        }
    }
}
</style>
