<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import { EventBus } from '@/plugins/events'

export default {
    name: 'PauseToday',
    props: {
        model: null,
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        isPausedToday() {
            return this.model?.specialClosures?.filter((rule) =>
                rule.date ? this.$date(rule.date).isToday() : false
            )
        },
    },
    methods: {
        set() {
            this.loading = true
            if (this.model.isActive)
                RestApiCollection.get(RestApiType.RESTAURANTS)
                    .deactivate(this.model.id)
                    .then(() => {
                        EventBus.$emit('reloadRestaurant')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            else
                RestApiCollection.get(RestApiType.RESTAURANTS)
                    .activate(this.model.id)
                    .then(() => {
                        EventBus.$emit('reloadRestaurant')
                    })
                    .finally(() => {
                        this.loading = false
                    })
        },
    },
}
</script>

<template lang="pug">
v-card.pause-today(v-if="model" elevation="0")
    v-card-text
        v-btn.mb-3(:color="model.isActive ? 'warning' : 'primary'" :loading="loading" @click="set")
            template(v-if="model.isActive") {{$t('labels.deactivate')}}
            template(v-else) {{$t('labels.activate')}}

        p.g-73--text {{ $t('texts.not_receive_order_until_activate') }}
</template>

<style scoped lang="scss"></style>
